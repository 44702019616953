;(function($) {

  $.fn.siteBlock = function( options ) {

    var s = $.extend({
          title: null,
          button_yes_text: null,
          button_no_text: null
        }, options );

    return this.each(function() {

        var el = $(this),
            blocker = null,
            modal = null,
            focused = false,
            approved = false;

        run();


        function run() {

          var confirmation_cookie = Cookies.get('age_confirmation');

          if( confirmation_cookie ) {
              return true;
          }

          $('body').addClass('siteBlocked');

          applyResize();

          modal = build_modal();

          block();
        }


        function block() {

          el.block({
            blockMsgClass: 'sitBlock-msg',
            centerY: false,
            css: { backgroundColor: '#8a3f3f', color: '#fff', top: '10%', cursor: 'default' },
            overlayCSS:  {
                backgroundColor: '#fff',
                opacity:         0.95,
                cursor:          'disable',
            },
            message: modal
          });

        }


        function applyResize() {

            var tick = false;

            $(window).resize( function() {

              if( ! tick && ! focused && ! approved ) {

                  setTimeout( function() {

                      el.unblock();
                      block();

                      tick = false;

                  }, 333 );

              }

              tick = true;

            });

        }


        function build_modal() {

            var title = $('<div/>', {
                  'html': s.title,
                  'style': 'font-size: 2.6rem; padding: 10px 0; text-transform: none',
                  'class': 'siteBlock-title'
                });

                var subtitle = $('<div/>', {
                      'html': s.bottom_text,
                      'style': 'font-size: 2.2rem; padding: 10px 0; text-transform: none',
                      'class': 'siteBlock-title'
                    });

            var form = $('<form/>', {
                  'style': 'padding: 15px; text-align: center',
                  'class': 'siteBlock-button'
                }).on('submit', function(ev) {

                    ev.preventDefault();

                    var age = age_input.val();

                    if( validate(age) ) {

                      Cookies.set('age_confirmation', 'yes', { expires: 90 });
                      el.unblock();

                      approved = true;

                    }else {
                        modal.html('<h2>Sorry, you are not old enough to view this site…</h2>');
                    }

                });

                var _button = $('<input/>', {
                      'style': 'font-size: 1.7rem; margin: 0 5px; border:0; border: solid 1px; padding: 9px 15px 8px 15px; vertical-align:top; background: white; text: black; cursor: pointer;',
                      'class': 'siteBlock-button',
                      type: 'submit'
                    });

                    var button_ok = _button.clone(true, true);

                        button_ok.val( 'OK' );

                        button_ok.on('mouseenter', function() {

                              $(this).css({background: '#DADADA'});

                          }).on('mouseleave', function() {

                              $(this).css({background: '#fff'});

                          });

                    var age_input = $('<input/>', {
                          class: 'siteBlock-age no-nums',
                          style: 'border: solid 1px; width: 90px; display:inline-block; padding: 7px; font-size: 2rem',
                          type: 'number',
                          required: 'required',
                          placeholder: 'YYYY',
                          min: 1900,
                          max: 2020,
                          autocomplete: 'off'
                        }).on('focusin', function(b) {

                            focused = true;

                        }).on('focusout', function(b) {
                            focused = false;
                        });

            var wrap = $('<div/>', {
                  'style':  'padding: 15px 25px; ',
                  'class':  'siteBlock'
                });


          title.appendTo( wrap );


          age_input.appendTo( form );
          button_ok.appendTo( form );

          form.appendTo( wrap );

          subtitle.appendTo( wrap );

        return wrap;
        }


        function validate( age ) {

          var valid = false;

            if( age && $.trim(age).length ) {

                var dt = new Date();
                var year = Math.round( dt.getFullYear() - age );

                if( year >= 18 ) {
                  valid = true;
                }
            }

        return valid;
        }


    });

  }

})(jQuery);
