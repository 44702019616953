var countries;

jQuery( document ).ready( function($) {

    countries = new Countries();

    if( $.fn.selectize && $('.selectize').length ) {

        countries.build();
    }

});


function Countries() {

      var $countries = jQuery('#countries');
      var $select = null;
      var currentValue = null;

        var update = function( selected ) {

          selected = selected || null;

          $countries = jQuery('#countries');

          if( $countries.find('option[value="'+selected+'"]') ) {
              $countries.val( selected );
              currentValue = selected;
          }

          build();
        }


        var build = function( ) {

          $select = $countries.selectize({
              sortField: 'text',
              placeholder: '-- All countries --',
              onFocus : function() {
                    $activeSelect = $select[0].selectize;
                    $value = $activeSelect.getValue();
                    if ( $value ) {
                      currentValue = $value;
                      $activeSelect.clear( true );
                    }
                    $activeSelect.settings.placeholder = 'type to filter...';
                    $activeSelect.updatePlaceholder();
              },
              onBlur : function() {

                $activeSelect = $select[0].selectize;
                $value = $activeSelect.getValue();

                if( $value ) currentValue = $value;

                if( ! currentValue ) {
                    $activeSelect.settings.placeholder = '-- All countries --';
                    $activeSelect.updatePlaceholder();
                } else {
                    $activeSelect.setValue( currentValue );
                }

              }
          });

        }


        var load = function( term ) {

            var _sel = $select.selectize()[0].selectize;
            var val = _sel.getValue(); console.log( val );

            _sel.destroy();

            jQuery.ajax({
              url: igtools.wp.ajax_url,
              data: {action: 'faf_update_countries', term: term},
              method: 'POST'
            }).done( function( res ) {

                jQuery('.countries-list-wrap').empty();

                if( res ) {

                    if( res.status === 'OK' ) {

                      jQuery('.countries-list-wrap').html( res.html );
                      update( val );

                    } else {
                      console.log( res.msg );
                    }

                } else {

                  console.log( 'No results.' );
                }

            });
        }

        return {
          update: update,
          build: build,
          load: load
        };
    }
