(function ($) {
  $(function () {
    $(".wak-vc-addon-wak_vc_donate").each(function () {
      var $addon = $(this);

      if ($addon.find("input:radio").is(":checked")) {
        $(this).parent().addClass("checked");
      } else {
        $(this).parent().removeClass("checked");
      }

      $addon.on("click", ".wak-donate-content-frequency-wrapper", function () {
        var $radio_button = $(this).find(".wak-donate-content-frequency");
        var unique_id = $radio_button.data("uniqueId");
        var frequency_id = $radio_button.val();

        $radio_button.prop("checked", true);
        $(".wak-donate-content-frequency-amounts-wrapper").hide();
        $("#wak_frequency_amount_" + unique_id + "_" + frequency_id).show();
        $addon.find(".wak-donate-content-frequency-wrapper").removeClass("checked");
        $radio_button.parent().addClass("checked");
      });

      $addon.on("click", ".wak-donate-content-frequency", function () {
        var unique_id = $(this).data("uniqueId");
        var frequency_id = $(this).val();

        $(".wak-donate-content-frequency-amounts-wrapper").hide();
        $("#wak_frequency_amount_" + unique_id + "_" + frequency_id).show();
        $addon.find(".wak-donate-content-frequency-wrapper").removeClass("checked");
        $(this).parent().addClass("checked");
      });

      $addon.on("click", ".wak-donate-content-frequency-amounts-amount-value", function () {
        var unique_id = $(this).data("uniqueId");
        var frequency_id = $(this).data("frequencyId");
        var amount = $(this).data("amount");
        var key = $(this).data("key");

        $(".wak-donate-content-frequency-amounts-amount-value").removeClass("active");
        $(this).addClass("active");

        $(
          "#wak_frequency_amount_" +
            unique_id +
            "_" +
            frequency_id +
            " .wak-donate-content-frequency-amounts-amount-wrapper"
        ).hide();
        $("#wak_frequency_amount_" + unique_id + "_" + frequency_id + "_" + key).show();

        $addon.find('input[name="amount"]').val(amount);
      });
    });
  });
})(jQuery);
