;(function($) {

  $.fn.linkExternal = function( options ) {

    var elements = [];
    var s = $.extend({
           text: "Open external url"
       }, options );

    return this.each(function() {

      var el = $(this),
          _wrap = el.find('.list-thumb'),
          link = $.trim( el.data('external') );

      //validate
      if( link === '' ) {
        return; // continue;
      }


      ;(function __init() {

        elements.push( el );

        _wrap.css({'position': 'relative'});

        assign_listeners();

      })();


      function assign_listeners() {

          el.on('mouseenter', function(e){

              _createOverlayer( $(this) );
          });

          el.on('mouseleave', function(e){

              _removeOverlayer( $(this) );
          });

      }


      function _createOverlayer( el ) {

          if( el.find('.le-wrap').lenght ) {
            return false;
          }

          var _text = '<span class="col-red" style="font-size: 2.9rem; vertical-align: baseline;">' + s.text + '<img class="ext col-red" style="vertical-align: baseline; max-width: 20px; margin-left: 2px;" src="/wp-content/themes/thefutureofbrands/assets/dist/img/icon-external.svg"/>' + '</span><br/>';
              _text += '<span style="text-transform: uppercase; font-size: 2rem;">' + el.data('external_name') + '</span>';

          var wrap = $('<div/>', {
                class: 'ext-wrap le-wrap',
                style:  'position: absolute; top:0; left:0; width: 100%; height: 100%; background: rgba(255,255,255, .85); display: flex; align-items: center; justify-content: center; font-size: 24px; color: black'
              });

          var _link  = $('<a/>', {
              html: _text,
              href:   link,
              target: '_blank',
              });

          var text = $('<div/>', {
              'class': 'le-text',
              'style': 'width: 100%',
              'html': _link
            });

            text.appendTo( wrap );

            wrap.appendTo( _wrap );
      }


      function _removeOverlayer( el ) {

        el.find( '.le-wrap' ).remove();
      }

    });

  };

})(jQuery);
