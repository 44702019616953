;(function($) {

  $.fn.getContent = function( options ) {

    var s = $.extend({
           reverse: false
       }, options );

    return this.each(function() {

      var el = $(this),
          _action = el.data('filter'),
          _bucket = $( el.data('bucket') );

          el.addClass('show_content');
          _bucket.find('.content').hide();


      el.on('click', function(c) {

        c.preventDefault();

        var el = $(this),
            post_id = el.data('get_content');

            var show = el.hasClass('show_content') ? true : false;
            var load = el.hasClass('content_loaded') ? false : true;

            if( show && load ) {

                _bucket.closest('.row').block({msg: 'Please wait...'});

                _get_content( el, post_id, _bucket );

                el.removeClass('show_content');
                el.html('Hide &times;');

            } else if( show ) {

                _bucket.slideDown();
                el.removeClass('show_content');
                el.html('Hide &times;');

            } else {
              //hide
              _bucket.slideUp();
              el.addClass('show_content');
              el.text('Read more +');

            }

      });


      function _get_content( el, post_id, assignTo ) {

        $.ajax({
          url: igtools.wp.ajax_url,
          data: {action: 'get_content', post_id: post_id},
          method: 'POST',
          assignTo: assignTo
        }).done(function(res) {

          if( res ) {

              if( res.status === 'OK' ) {

                _bucket.find('.excerpt').hide();
                assignTo.hide().html( res.html ).slideDown();
                el.addClass('content_loaded');

              } else {
                console.log( res.msg );
              }

          } else {

            console.error( 'No results.' );
          }

          assignTo.closest('.row').unblock();

        });
      }


      function assign_listeners( buttons ) {


      }


    });

  };

})(jQuery);
