;(function ($) {
    $(function () {
        MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

        add_address_lookup_options();
        add_page_breaker_options();

        var observer = new MutationObserver(function(mutations, observer) {
            add_address_lookup_options();
            add_page_breaker_options();
        });
          
        observer.observe(document, {
            subtree: true,
            attributes: true
        });

        function add_address_lookup_options() {
            $('#gform_fields').each(function () {
                var $gform_fields = $(this);
                
                if ($('#field_settings').length > 0) {
                    if (window.fieldSettings.text.indexOf('wak-gf-address-lookup-options-wrapper') == -1) {
                        window.fieldSettings.text += ', .wak-gf-address-lookup-options-wrapper';
                    }

                    if (!has_address_lookup_options($gform_fields)) {
                        if (should_have_address_lookup_options()) {
                            $('#field_settings #gform_tab_2 > ul').append(
                                $('<li></li>')
                                    .attr({
                                        'class': 'wak-gf-address-lookup-options-wrapper'
                                    }).html(
                                    '<input type="checkbox" id="field_address_lookup" name="field_address_lookup" class="field-address-lookup-option" onchange="lookup_loqate_update_classes_from_fields(this);" value="1">' +
                                    '<label for="field_address_lookup" class="inline">Enable Address Lookup</label>' +
                                    '<div id="field_address_lookup_options">' +
                                    '    <ul>' +
                                    '        <li>' +
                                    '            <label for="field_address_lookup_options_al1_field" class="section_label">' +
                                    '                Address line 1' +
                                    '            </label>' +
                                    '            <select id="field_address_lookup_options_al1_field" name="field_address_lookup_options_al1_field" class="field-address-lookup-option inputs-list" onchange="lookup_loqate_update_classes_from_fields(this);">' +
                                    '                <option value="">Select a field</option>' +
                                    '            </select>' +
                                    '        </li>' +
                                    '        <li>' +
                                    '            <label for="field_address_lookup_options_al2_field" class="section_label">' +
                                    '                Address line 2' +
                                    '            </label>' +
                                    '            <select id="field_address_lookup_options_al2_field" name="field_address_lookup_options_al2_field" class="field-address-lookup-option inputs-list" onchange="lookup_loqate_update_classes_from_fields(this);">' +
                                    '                <option value="">Select a field</option>' +
                                    '            </select>' +
                                    '        </li>' +
                                    '        <li>' +
                                    '            <label for="field_address_lookup_options_c_field" class="section_label">' +
                                    '                City' +
                                    '            </label>' +
                                    '            <select id="field_address_lookup_options_c_field" name="field_address_lookup_options_c_field" class="field-address-lookup-option inputs-list" onchange="lookup_loqate_update_classes_from_fields(this);">' +
                                    '                <option value="">Select a field</option>' +
                                    '            </select>' +
                                    '        </li>' +
                                    '        <li>' +
                                    '            <label for="field_address_lookup_options_pc_field" class="section_label">' +
                                    '                Postal code' +
                                    '            </label>' +
                                    '            <select id="field_address_lookup_options_pc_field" name="field_address_lookup_options_pc_field" class="field-address-lookup-option inputs-list" onchange="lookup_loqate_update_classes_from_fields(this);">' +
                                    '                <option value="">Select a field</option>' +
                                    '            </select>' +
                                    '        </li>' +
                                    '        <li>' +
                                    '            <label for="field_address_lookup_options_county_field" class="section_label">' +
                                    '                County' +
                                    '            </label>' +
                                    '            <select id="field_address_lookup_options_county_field" name="field_address_lookup_options_county_field" class="field-address-lookup-option inputs-list" onchange="lookup_loqate_update_classes_from_fields(this);">' +
                                    '                <option value="">Select a field</option>' +
                                    '            </select>' +
                                    '        </li>' +
                                    '    </ul>' +
                                    '</div>'
                                )
                            );
                        }
                    } else {
                        if (!should_have_address_lookup_options()) {
                            $('#field_settings #gform_tab_2 > ul li.wak-gf-address-lookup-options-wrapper').remove();
                        }
                    }

                    if (!is_advanced_tab_displayed()) {
                        var field_classes = $('#field_css_class').val().split(/\s+/);
                        var settings_from_classes = lookup_loqate_classes_to_config(field_classes);

                        update_inputs_lists_fields($gform_fields);
                        update_fields_from_settings(settings_from_classes);
                        update_checkbox_from_classes(field_classes);
                    }
                }
    
                function get_inputs_list($gform_fields) {
                    var list = {};
    
                    $gform_fields.find('.gfield .ginput_container input').each(function () {
                        var id = $(this).attr('id');

                        if (id != 'undefined') {
                            var label   = id + ': ' + $(this).parents('.gfield').find('label[for="' + id + '"]').text();
                            list[id]    = label;
                        }
                    });
    
                    return list;
                }

                function update_inputs_lists_fields($gform_fields) {
                    var inputs_list = get_inputs_list($gform_fields);

                    $('#field_settings #gform_tab_2 .field-address-lookup-option.inputs-list option').not('[value=""]').remove();

                    var html = $('#field_settings #gform_tab_2 .field-address-lookup-option.inputs-list').eq(0).html();
                    for (var id in inputs_list) {
                        html += '<option value="' + id + '">' + inputs_list[id] + '</option>';
                    }

                    $('#field_settings #gform_tab_2 .field-address-lookup-option.inputs-list').html(html);
                }

                function update_fields_from_settings(settings) {
                    if (settings.Line1) {
                        $('#field_settings #gform_tab_2 #field_address_lookup_options_al1_field').val(settings.Line1);
                    }
                    if (settings.Line2) {
                        $('#field_settings #gform_tab_2 #field_address_lookup_options_al2_field').val(settings.Line2);
                    }
                    if (settings.City) {
                        $('#field_settings #gform_tab_2 #field_address_lookup_options_c_field').val(settings.City);
                    }
                    if (settings.PostalCode) {
                        $('#field_settings #gform_tab_2 #field_address_lookup_options_pc_field').val(settings.PostalCode);
                    }
                    if (settings.AdminAreaName) {
                        $('#field_settings #gform_tab_2 #field_address_lookup_options_county_field').val(settings.AdminAreaName);
                    }
                }

                function update_checkbox_from_classes(classes) {
                    if (classes.indexOf('address_lookup_loqate') !== -1) {
                        $('#field_settings #gform_tab_2 #field_address_lookup').prop('checked', true);
                    } else {
                        $('#field_settings #gform_tab_2 #field_address_lookup').prop('checked', false);
                    }
                }

                function lookup_loqate_classes_to_config(classes) {
                    var config = {};
                    
                    for (var i = 0; i < classes.length; i++) {
                        if (classes[i].indexOf('loqate---') == 0) {
                            class_parts             = classes[i].split('---');
                            config[class_parts[2]]  = class_parts[1];
                        }
                    }
                    
                    return config;
                }

                function has_address_lookup_options() {
                    return $('#field_settings').find('.wak-gf-address-lookup-options-wrapper').length > 0;
                }

                function should_have_address_lookup_options() {
                    return $('#field_settings').parents('.gfield').find('.ginput_container_text').length > 0;
                }

                function is_advanced_tab_displayed() {
                    return $('#field_settings [aria-controls="gform_tab_2"]').attr('aria-selected') == 'true';
                }
            });
        }

        function add_page_breaker_options() {
            $('#gform_fields').each(function () {
                var $gform_fields = $(this);

                if ($('#field_settings').length > 0) {
                    if (window.fieldSettings.page.indexOf('wak-gf-page-breaker-options-wrapper') == -1) {
                        window.fieldSettings.page += ', .wak-gf-page-breaker-options-wrapper';
                    }

                    if (!has_page_breaker_options($gform_fields)) {
                        if (should_have_page_breaker_options()) {
                            $('#field_settings #gform_tab_2 > ul').append(
                                $('<li></li>')
                                    .attr({
                                        'class': 'wak-gf-page-breaker-options-wrapper'
                                    }).html(
                                        '<ul>' +
                                        '    <li>' +
                                        '       <input type="checkbox" id="field_pb_hide_previous" name="field_pb_hide_previous" class="field-page-breaker-option" onchange="page_braker_update_classes_from_fields(this);" value="1">' +
                                        '       <label for="field_pb_hide_previous" class="inline">Hide previous button</label>' +
                                        '    </li>' +
                                        '    <li>' +
                                        '       <input type="checkbox" id="field_pb_show_previous_top" name="field_pb_show_previous_top" class="field-page-breaker-option" onchange="page_braker_update_classes_from_fields(this);" value="1">' +
                                        '       <label for="field_pb_show_previous_top" class="inline">Show previous button at top</label>' +
                                        '    </li>' +
                                        '</ul>'
                                    )
                            );
                        }
                    } else {
                        if (!should_have_page_breaker_options()) {
                            $('#field_settings #gform_tab_2 > ul li.wak-gf-page-breaker-options-wrapper').remove();
                        }
                    }

                    if (!is_advanced_tab_displayed()) {
                        var field_classes = $('#field_css_class').val().split(/\s+/);

                        update_checkbox_from_classes(field_classes);
                    }
                }

                function update_checkbox_from_classes(classes) {
                    if (classes.indexOf('no-previous') !== -1) {
                        $('#field_settings #gform_tab_2 #field_pb_hide_previous').prop('checked', true);
                    } else {
                        $('#field_settings #gform_tab_2 #field_pb_hide_previous').prop('checked', false);
                    }

                    if (classes.indexOf('add-previous-at-top') !== -1) {
                        $('#field_settings #gform_tab_2 #field_pb_show_previous_top').prop('checked', true);
                    } else {
                        $('#field_settings #gform_tab_2 #field_pb_show_previous_top').prop('checked', false);
                    }
                }

                function has_page_breaker_options() {
                    return $('#field_settings').find('.wak-gf-page-breaker-options-wrapper').length > 0;
                }

                function should_have_page_breaker_options() {
                    return $('#field_settings').parents('.gfield').hasClass('gpage');
                }

                function is_advanced_tab_displayed() {
                    return $('#field_settings [aria-controls="gform_tab_2"]').attr('aria-selected') == 'true';
                }
            });
        }
    });
})(jQuery);

function lookup_loqate_update_classes_from_fields(el) {
    var classes                 = jQuery('#field_css_class').val() == '' ? [] : jQuery('#field_css_class').val().split(/\s+/);
    var settings_from_classes   = lookup_loqate_classes_to_config_extended(classes);
    
    if (el.id == 'field_address_lookup') {
        if (el.checked) {
            if (classes.indexOf('address_lookup_loqate') === -1) {
                classes.push('address_lookup_loqate');
            }
        } else {
            if (classes.indexOf('address_lookup_loqate') !== -1) {
                classes.splice(classes.indexOf('address_lookup_loqate'), 1);
            }
        }
    }
    
    if (el.id == 'field_address_lookup_options_al1_field') {
        if (settings_from_classes.Line1) {
            classes.splice(classes.indexOf(settings_from_classes.Line1.class), 1);
        }
        if (el.value) {
            classes.push('loqate---' + el.value + '---Line1');
        }
    }

    if (el.id == 'field_address_lookup_options_al2_field') {
        if (settings_from_classes.Line2) {
            classes.splice(classes.indexOf(settings_from_classes.Line2.class), 1);
        }
        if (el.value) {
            classes.push('loqate---' + el.value + '---Line2');
        }
    }

    if (el.id == 'field_address_lookup_options_c_field') {
        if (settings_from_classes.City) {
            classes.splice(classes.indexOf(settings_from_classes.City.class), 1);
        }
        if (el.value) {
            classes.push('loqate---' + el.value + '---City');
        }
    }

    if (el.id == 'field_address_lookup_options_pc_field') {
        if (settings_from_classes.PostalCode) {
            classes.splice(classes.indexOf(settings_from_classes.PostalCode.class), 1);
        }
        if (el.value) {
            classes.push('loqate---' + el.value + '---PostalCode');
        }
    }

    if (el.id == 'field_address_lookup_options_county_field') {
        if (settings_from_classes.AdminAreaName) {
            classes.splice(classes.indexOf(settings_from_classes.AdminAreaName.class), 1);
        }
        if (el.value) {
            classes.push('loqate---' + el.value + '---AdminAreaName');
        }
    }

    SetFieldProperty("cssClass", classes.join(' '))
    jQuery('#field_css_class').val(classes.join(' '));
}

function lookup_loqate_classes_to_config_extended(classes) {
    var config = {};
    
    for (var i = 0; i < classes.length; i++) {
        if (classes[i].indexOf('loqate---') == 0) {
            class_parts             = classes[i].split('---');
            config[class_parts[2]]  = {
                'class'     : classes[i],
                'property'  : class_parts[2],
                'field'     : class_parts[1]
            };
        }
    }
    
    return config;
}

function page_braker_update_classes_from_fields(el) {
    var classes = jQuery('#field_css_class').val() == '' ? [] : jQuery('#field_css_class').val().split(/\s+/);
    
    if (el.id == 'field_pb_hide_previous') {
        if (el.checked) {
            if (classes.indexOf('no-previous') === -1) {
                classes.push('no-previous');
            }
        } else {
            if (classes.indexOf('no-previous') !== -1) {
                classes.splice(classes.indexOf('no-previous'), 1);
            }
        }
    }

    if (el.id == 'field_pb_show_previous_top') {
        if (el.checked) {
            if (classes.indexOf('add-previous-at-top') === -1) {
                classes.push('add-previous-at-top');
            }
        } else {
            if (classes.indexOf('add-previous-at-top') !== -1) {
                classes.splice(classes.indexOf('add-previous-at-top'), 1);
            }
        }
    }

    SetFieldProperty("cssClass", classes.join(' '))
    jQuery('#field_css_class').val(classes.join(' '));
}