;(function($) {

  $.fn.newsletter = function( options ) {

    var s = $.extend({
          action: null,
          list_id: null
        }, options );

    return this.each(function() {

        var _form = $(this);

            if( ! s.action || ! s.list_id ) {
              console.log('Action or list id is not set.');
              return false;
            }

            _form.on('submit', function(send) {

                send.preventDefault();

                _form.block();

                var email = _form.find('#user_email').val();

                $.ajax({
                  url: igtools.wp.ajax_url,
                  data: {action: s.action, list_id: s.list_id, email: email },
                  method: 'POST'
                }).done( function(res) {

                  if( res ) {

                      if( res.status === 'OK' ) {

                          _form.slideUp(function() {
                            _form.parent().append('<h3 style="color: green; font-size: 24px; text-align:center;">Your email has been added successfully</h3>');
                          });

                          $( document ).trigger('newsletterSent');

                      } else {
                        console.log( res.msg );
                      }

                  } else {
                    console.log( 'No results.' );
                  }

                  _form.unblock();

                });
           });
    });

  }

})(jQuery);
