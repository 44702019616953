// get all tabs
var allTabs = document.querySelectorAll(".vc_tta-tab");

for (i = 0; i < allTabs.length; i++) {
  // when each tab clicked 
  allTabs[i].onclick = function(){
    // add vc_active class to the tab
    this.classList.add("vc_active");
    // remove vc_active class from the tab's siblings
    for (let sibling of this.parentNode.children) {
      if (sibling !== this) sibling.classList.remove('vc_active');
    }
    // the tab's index
    let index = getChildElementIndex(this);
    // find the matching panel using the tab's index
    let parent = this.closest(".vc_tta-tabs");
    let panel = parent.querySelector(".vc_tta-panels");
    let matchingPanel = panel.children[index];
    // add vc_active class to the matching panel
    matchingPanel.classList.add("vc_active");
    // remove vc_active class from matchingPanel's siblings
    for (let sibling of matchingPanel.parentNode.children) {
      if (sibling !== matchingPanel) sibling.classList.remove('vc_active');
    }
  };
}

function getChildElementIndex(node) {
  return Array.prototype.indexOf.call(node.parentNode.children, node);
}