(function ($) {
  $(document).ready(function () {
    MutationObserver = window.MutationObserver || window.WebKitMutationObserver;

    // radio buttons
    if ($("input:radio:checked")) {
      $("input:radio:checked").parent("li").addClass("selected");
    }

    if ($("input:checkbox:checked")) {
      $("input:checkbox:checked").parent("li").addClass("selected");
    }

    // Add privacy policy link to newsletter consent label
    $(".widget_wak_newsletter_widget .gfield_consent_label").wrapInner("<a href='/privacy-policy' target='_blank'></a>");

    function update_gravity_forms() {
      $(".gform_wrapper").each(function () {
        var $form = $(this).find("form");

        if (has_pages($form)) {
          update_gravity_forms_pages($form);

          add_steps_line($form);
        }

        if (has_summary($form)) {
          create_summary_page($form);
        }

        $form.find(".gfield_radio input").off("click", add_selected_on_radio).on("click", add_selected_on_radio);

        function add_selected_on_radio() {
          $form.find(".gfield_radio li").removeClass("selected");
          $form.find(".gfield_radio input").each(function () {
            if ($(this).eq(0).is(":checked")) {
              $(this).eq(0).parent("li").addClass("selected");
            }
          });
        }

        $form
          .find(".gfield_checkbox input")
          .off("click", add_selected_on_checkbox)
          .on("click", add_selected_on_checkbox);

        function add_selected_on_checkbox() {
          $form.find(".gfield_checkbox li").removeClass("selected");
          $form.find(".gfield_checkbox input").each(function () {
            if ($(this).eq(0).is(":checked")) {
              $(this).eq(0).parent("li").addClass("selected");
            }
          });
        }
      });

      function has_pages($form) {
        return $form.children(".gf_page_steps").length > 0;
      }
      function has_summary($form) {
        return $form.hasClass("has-summary-page");
      }
    }
    update_gravity_forms();

    var observer = new MutationObserver(function (mutations, observer) {
      update_gravity_forms();
      address_lookup_loqate();
    });

    observer.observe(document, {
      subtree: true,
      attributes: true
    });

    function address_lookup_loqate() {
      $(".address_lookup_loqate").each(function () {
        if (!$(this).is(":visible")) {
          return false;
        }

        var classes = $(this).attr("class").split(/\s+/);
        var config = lookup_loqate_classes_to_config(classes);
        var $input = $(this).find("input");
        var $gform_body = $(this).parents(".gform_body");

        if ($input.hasClass("select2-hidden-accessible")) {
          return false;
        }

        $input.select2({
          placeholder: $input.attr("placeholder"),
          closeOnSelect: false,
          minimumInputLength: 2,
          maximumSelectionLength: 1,
          ajax: {
            delay: 500,
            url: "https://api.addressy.com/Capture/Interactive/Find/v1.10/json3.ws",
            data: function (params) {
              if ($input.data("id")) {
                return {
                  Key: waktools.wp.loqate_key,
                  Container: $input.data("id"),
                  Countries: "United Kingdom",
                  Limit: 1000
                };
              } else {
                return {
                  Key: waktools.wp.loqate_key,
                  Text: params.term,
                  Countries: "United Kingdom",
                  Limit: 1000
                };
              }
            },
            type: "POST",
            processResults: function (data) {
              var results = [];

              $.each(data.Items, function (k, v) {
                results.push({
                  id: v.Id,
                  text: v.Text,
                  item: v
                });
              });

              return {
                results: results,
                pagination: {
                  more: false
                }
              };
            }
          },
          templateResult: function (item) {
            if (item.loading) {
              return item.text;
            }

            var $container = $(
              "<div class='select2-result-repository clearfix'>" +
                "<div class='select2-result-repository__meta'>" +
                "<div class='select2-result-repository__title'></div>" +
                "</div>" +
                "</div>"
            );

            $container.find(".select2-result-repository__title").text(item.text + " " + item.item.Description);

            return $container;
          },
          templateSelection: function (state) {
            if (!state.id) {
              return state.text;
            }

            var $state = $("<span></span>");

            $state.text(state.text);

            return $state;
          }
        });

        $("body").on("keydown", ".select2-container.select2-container--open .select2-search__field", function () {
          $input.data("id", "");
        });

        $input.on("select2:select", function (e) {
          if (e.params.data.item.Type == "Address") {
            $.ajax({
              url: "https://api.addressy.com/Capture/Interactive/Retrieve/v1.00/json3.ws",
              data: {
                Key: waktools.wp.loqate_key,
                Id: e.params.data.item.Id
              },
              type: "POST",
              error: function () {
                console.error("Couldn't get address.");
              },
              success: function (data) {
                if (data.Items.length == 1 && typeof data.Items[0].Error != "undefined") {
                  console.error(data.Items[0].Description);
                } else {
                  for (var field_name in config) {
                    $gform_body.find('[name="' + field_name + '"]').val(data.Items[0][config[field_name]]);
                  }

                  if ($input.val()) {
                    $input
                      .next()
                      .find(".select2-selection__rendered")
                      .html(
                        '<span class="select2-selection__rendered" role="textbox" aria-readonly="true" title="' +
                          $input.val() +
                          '">' +
                          $input.val() +
                          "</span>"
                      );
                    $input.select2("close");
                  } else {
                    $input
                      .next()
                      .find(".select2-selection__rendered")
                      .html('<span class="select2-selection__placeholder">' + $input.attr("placeholder") + "</span>");
                  }
                }
              }
            });
          } else {
            $input.data("id", e.params.data.item.Id);
            $(".select2-container.select2-container--open .select2-search__field")
              .val(e.params.data.item.Text + " " + e.params.data.item.Description)
              .trigger("input");
          }
        });
      });
    }
    address_lookup_loqate();

    function lookup_loqate_classes_to_config(classes) {
      var config = {};

      for (var i = 0; i < classes.length; i++) {
        if (classes[i].indexOf("loqate---") == 0) {
          class_parts = classes[i].split("---");
          config[class_parts[1]] = class_parts[2];
        }
      }

      return config;
    }

    function update_gravity_forms_pages($form) {
      if (has_display_outside_of_pages_fields($form)) {
        move_outside_of_pages_fields($form);
      }

      // add_edit_link_to_completed_pages($form);
      add_back_button_to_pages($form);

      function has_display_outside_of_pages_fields($form) {
        return $form.find(".display-outside-of-pages").length > 0;
      }
    }

    function add_steps_line($form) {
      if ($form.find(".steps-line").length > 0) {
        return;
      }
      $form.find(".gf_page_steps").after('<div class="steps-and-line-wrapper"></div>');
      $form.find(".steps-and-line-wrapper").append('<div class="steps-line"></div>');
      $form.find(".gf_page_steps").appendTo($form.find(".steps-and-line-wrapper"));
    }

    function move_outside_of_pages_fields($form) {
      var form_id = $form.attr("id");

      if (!has_outside_pages_body($form)) {
        var list_classes = $form.find(".gform_page .gform_page_fields .gform_fields").eq(0).attr("class");
        var ouside_pages_body_html =
          '<div id="gform_body_outside_pages_' +
          form_id +
          '" class="gform_body"><ul id="gform_fields_' +
          form_id +
          '_0" class="' +
          list_classes +
          '"></ul></div>';

        if ($form.find(".gform_heading").length > 0) {
          $form.find(".gform_heading").after(ouside_pages_body_html);
        } else {
          $form.prepend(ouside_pages_body_html);
        }
      }

      $fields_list = $form.find("#gform_fields_" + form_id + "_0");

      $form.find(".display-outside-of-pages").each(function () {
        $(this).appendTo($fields_list);
      });

      function has_outside_pages_body($form) {
        return $form.find("#gform_body_outside_pages_" + $form.attr("id")).length > 0;
      }
    }

    function add_edit_link_to_completed_pages($form) {
      var form_id_number = $form.attr("id").replace("gform_", "");

      $form.find(".gf_page_steps .gf_step_completed").each(function () {
        var $this = $(this);
        var current_step_number = $this.children(".gf_step_number").html();

        if (!has_edit_link($this)) {
          $this.append(
            $("<a></a>")
              .attr({
                href: "javascript:void(0);",
                onclick:
                  'jQuery("#gform_target_page_number_' +
                  form_id_number +
                  '").val("' +
                  current_step_number +
                  '");jQuery("#gform_' +
                  form_id_number +
                  '").trigger("submit",[true]);',
                title: "Edit",
                class: "wak-complete-page-edit-link"
              })
              .html("Edit")
          );
        }
      });

      function has_edit_link($gf_step_completed) {
        return $gf_step_completed.children(".wak-complete-page-edit-link").length > 0;
      }
    }

    function add_back_button_to_pages($form) {
      var form_id_number = $form.attr("id").replace("gform_", "");
      var current_displayed_page = $("#gform_source_page_number_" + form_id_number).val();
      var should_add_button_top = $("#gform_page_" + form_id_number + "_" + current_displayed_page).hasClass(
        "add-previous-at-top"
      );
      var top_button_label =
        $("#gform_page_" + form_id_number + "_" + current_displayed_page + " .gform_previous_button").length > 0
          ? $("#gform_page_" + form_id_number + "_" + current_displayed_page + " .gform_previous_button").val()
          : "Go to previous step";

      if (!has_back_button($form) && current_displayed_page > 1 && should_add_button_top) {
        setTimeout(function () {
          $form.find(".steps-and-line-wrapper").after(
            $("<div></div>")
              .attr({
                class: "wak-page-back-button-wrapper"
              })
              .html(
                $("<button></button>")
                  .attr({
                    type: "button",
                    class: "wak-page-back-button",
                    onclick:
                      'jQuery("#gform_target_page_number_' +
                      form_id_number +
                      '").val("' +
                      (current_displayed_page - 1) +
                      '");jQuery("#gform_' +
                      form_id_number +
                      '").trigger("submit",[true]);'
                  })
                  .html('<i class="fas fa-arrow-left"></i> ' + top_button_label)
              )
          );
        }, 0);
      } else {
        $form.find(".wak-page-back-button-wrapper").remove();
      }

      function has_back_button($form) {
        return $form.children(".wak-page-back-button").length > 0;
      }
    }

    function create_summary_page($form) {
      var form_id_number = $form.attr("id").replace("gform_", "");
      var $page_wrapper = $form.find(".gform_review_page .gform_fields");

      $page_wrapper.html("");
      $page_wrapper.append(
        '<li class="gfield gsection field_sublabel_below field_description_below gfield_visibility_visible"><h2 class="gsection_title">Summary</h2></li>'
      );

      $form
        .find(".gf_page_steps .gf_step")
        .not(":last")
        .each(function () {
          var $gf_step = $(this);
          var current_step_number = $gf_step.children(".gf_step_number").html();
          var current_step_name = $gf_step.children(".gf_step_label").html();

          $page_wrapper.append(
            '<li id="gf_form_' +
              form_id_number +
              "_summary_page_" +
              current_step_number +
              '" class="gfield gsection field_sublabel_below field_description_below gfield_visibility_visible">' +
              '<div class="summary_section_title_wrapper">' +
              '<h3 class="summary_section_title">' +
              current_step_name +
              "</h3>" +
              '<a class="summary_section_edit_link" href="javascript:void(0);" onclick="jQuery(\'#gform_target_page_number_' +
              form_id_number +
              "').val('" +
              current_step_number +
              "');jQuery('#gform_" +
              form_id_number +
              "').trigger('submit',[true]);\">Edit</a>" +
              "</div>" +
              '<div class="summary_section_content_wrapper">' +
              "</div>" +
              "</li>"
          );

          var $content_wrapper = $(
            "#gf_form_" + form_id_number + "_summary_page_" + current_step_number + " .summary_section_content_wrapper"
          );

          $("#gform_page_" + form_id_number + "_" + current_step_number + " .gform_fields .gfield").each(function () {
            var $gfield = $(this);
            var field_label = "";
            var field_value = "";

            if (
              $gfield.children(".ginput_container_text").length > 0 ||
              $gfield.children(".ginput_container_email").length > 0 ||
              $gfield.children(".ginput_container_phone").length > 0 ||
              $gfield.children(".ginput_container_number").length > 0 ||
              $gfield.children(".ginput_container_date").length > 0 ||
              $gfield.children(".ginput_container_website").length > 0 ||
              $gfield.children(".ginput_container_post_title").length > 0 ||
              $gfield.children(".ginput_container_post_tags").length > 0
            ) {
              field_label = $gfield.children(".gfield_label").html();
              field_value = $gfield.find(".ginput_container input").val();
            } else if (
              $gfield.children(".ginput_container_select").length > 0 ||
              $gfield.children(".ginput_container_multiselect").length > 0
            ) {
              var field_values = [];
              var field_values_raw = $gfield.find(".ginput_container select").val();

              if (!Array.isArray(field_values_raw)) {
                field_values_raw = [field_values_raw];
              }

              for (var i in field_values_raw) {
                field_values.push(
                  $gfield.find('.ginput_container select option[value="' + field_values_raw[i] + '"]').html()
                );
              }

              field_label = $gfield.children(".gfield_label").html();
              field_value = field_values.join(", ");
            } else if (
              $gfield.children(".ginput_container_radio").length > 0 ||
              $gfield.children(".ginput_container_checkbox").length > 0 ||
              $gfield.children(".ginput_container_consent").length > 0
            ) {
              var field_values = [];
              field_label = $gfield.children(".gfield_label").html();

              $gfield.find("input:checked").each(function () {
                field_values.push($(this).next("label").html());
              });

              field_value = field_values.join(", ");
            } else if (
              $gfield.children(".ginput_container_textarea").length > 0 ||
              $gfield.children(".ginput_container_post_excerpt").length > 0
            ) {
              field_label = $gfield.children(".gfield_label").html();
              field_value = $gfield.find(".ginput_container textarea").val();
            } else if ($gfield.children(".ginput_container_name").length > 0) {
              field_label = $gfield.children(".gfield_label").html();
              field_value =
                $gfield.find(".ginput_container input").eq(0).val() +
                " " +
                $gfield.find(".ginput_container input").eq(1).val();
            } else if ($gfield.children(".ginput_container_time").length > 0) {
              field_label = $gfield.children(".gfield_label").html();
              field_value =
                $gfield.find(".ginput_container input").eq(0).val() +
                " " +
                $gfield.find(".ginput_container input").eq(1).val() +
                $gfield
                  .find(
                    '.ginput_container select option[value="' + $gfield.find(".ginput_container select").val() + '"]'
                  )
                  .html();
            } else if ($gfield.children(".ginput_container_address").length > 0) {
              field_label = $gfield.children(".gfield_label").html();
              field_value =
                $gfield.find(".ginput_container input").eq(0).val() +
                "<br>" +
                $gfield.find(".ginput_container input").eq(1).val() +
                "<br>" +
                $gfield.find(".ginput_container input").eq(2).val() +
                "<br>" +
                $gfield.find(".ginput_container input").eq(3).val() +
                "<br>" +
                $gfield.find(".ginput_container input").eq(4).val() +
                "<br>" +
                $gfield
                  .find(
                    '.ginput_container select option[value="' + $gfield.find(".ginput_container select").val() + '"]'
                  )
                  .html();
            } else if (
              $gfield.children(".ginput_container_fileupload").length > 0 ||
              $gfield.children(".ginput_container_post_image").length > 0
            ) {
              field_label = $gfield.children(".gfield_label").html();
              field_value = $gfield.find('.ginput_container input[type="file"]').val()
                ? $gfield.find('.ginput_container input[type="file"]').val()
                : $gfield.find(".ginput_container .ginput_preview strong").html()
                ? $gfield.find(".ginput_container .ginput_preview strong").html()
                : "";
            } else if ($gfield.children(".ginput_container_list").length > 0) {
              var field_values = [];
              field_label = $gfield.children(".gfield_label").html();

              $gfield.find("input").each(function () {
                field_values.push($(this).val());
              });

              field_value = field_values.join(", ");
            }

            if (!has_conditional_logic($gfield) || (has_conditional_logic($gfield) && field_value != "")) {
              $content_wrapper.append(
                '<div class="summary_section_content_field">' +
                  '<div class="summary_section_content_label">' +
                  field_label +
                  "</div>" +
                  '<div class="summary_section_content_value">' +
                  field_value +
                  "</div>" +
                  "</div>"
              );
            }
          });
        });

      $page_wrapper.addClass("wak-summary-page-created");

      function has_conditional_logic($gfield) {
        return $gfield.hasClass("has-conditional-logic");
      }
    }
  });
})(jQuery);
