;(function($) {

  $.fn.quizz = function( options ) {

    var s = $.extend({

       }, options );


    return this.each(function() {

      var quiz = $(this),
          quiz_id = quiz.data('quiz'),
          questions = [],
          answered = 0;

      ;(function __init() {

          setup_quiz();

      })();


      function setup_quiz() {

          quiz.find('.question-wrap').each( function( ix, _q ) {

                var question = $( _q );

                questions.push( question );

                question.find('a.answer').each( function( ix, _a ) {

                    var answer = $( _a );

                    answer.on('click', function(c) {

                        c.preventDefault();

                        var result_wrap = question.find('.quiz-result');

                        answer.closest('.question-wrap').find('.answer').removeClass('chosen');
                        answer.addClass('chosen');

                        var valid = check_answer( answer );

                        question.attr('data-answer', valid);
                        question.addClass('selected');

                        result_wrap.removeClass('correct').removeClass('wrong');

                        if( valid ) {
                            result_wrap.addClass('correct');
                        } else {
                            result_wrap.addClass('wrong');
                        }

                        resolve_finish();
                        //or instant
                        resolve_instant( answer.closest('.question-wrap'), valid );
                    });

                });

          });

      }


      function resolve_instant( answered, valid ) {

          answered.find('.answer-box-wrap').hide();
          answered.find('.quiz-result').show();

      }


      function resolve_finish() {

          var is_finish = true;

          questions.forEach( function( q ) {

              if( ! q.is('.selected') ) {
                is_finish = false;
              }

          } );

          if( is_finish ) {

              show_result();
          }

      }


      function show_result() {

          $('body').block({ message: '<h1>Checking result...</h1>' });

          setTimeout( function() {

              questions.forEach( function( answered ) {

                  answered.find('.answer-box-wrap').hide();
                  answered.find('.quiz-result').show();
              });

              apply_reward();

              setup_fb_snapshot();
              setup_ln_snapshot();

              $('body').unblock();

              var reward_top = $('.reward.final_result').position().top;

              $("html, body").animate({ scrollTop: reward_top }, "slow");

          }, 1000 );

      }


      function setup_ln_snapshot() {

        var ln_button = $('.ln_share_button');

        var url = ln_button.data('ln-url');
        var title = ln_button.data('ln-title');
        var summary = ln_button.data('ln-summary');
        var source = ln_button.data('ln-source');

        //var share_link = 'https://www.linkedin.com/sharing/share-offsite/?url=' + quiz_data.fb.href;

        var share_link = 'https://www.linkedin.com/shareArticle?mini=true&url=' + url + '&title=' + title + '&summary=' + summary + '&source=' + source;
            share_link = encodeURI( share_link );

        ln_button.find('a').attr('href', share_link);

      }


      function setup_fb_snapshot() {

          //quiz_data.fb.name = 'You are a lawyer!';
          //quiz_data.fb.caption = 'Find more on our website.';
          //quiz_data.fb.description = 'Find more on our website. more description.';
          var title = $('.reward.final_result').data('title');
          quiz_data.fb.quote = 'I am a '+ title +'!';

          //$('meta[property="og:title"]').attr('content', 'Test tile');
          //$('meta[property="og:url"]').attr('content', 'http://thefutureofbrands.ignis.works/quiz-page/?p=lawyer');
      }

      function apply_reward() {

          var count_correct = 0,
              winner_group = 1;


          //check how many correct
          questions.forEach( function( answered ) {

              if( answered.data('answer') == 1 ) {
                count_correct++;
              }
          });

          var quiz_groups = quiz_data[quiz_id].groups;

          for(var i=0; i <= quiz_groups.length; i++ ) {

              var group_value = parseInt(quiz_groups[i]);

              if( quiz_groups.length > 1 && count_correct <= group_value ) {
                  winner_group = i;
                  break;
              }
          }

          quiz.find('.reward_score_value').html( 'You got ' + count_correct + ' out of ' + questions.length + ' right!' );
          quiz.find('.rewards .reward[data-reward="'+ winner_group +'"]').addClass('final_result').show();

      }


      function check_answer( answer ) {

          var is_valid = answer.data('valid');

      return ( is_valid && is_valid === 'yes' ) ? 1 : 0;
      }

    });

  }

})(jQuery);
