$(".vc_tta-panel[data-vc-content='.vc_tta-panel-body']").on("click", function () {
  const $this = $(this);
  const siblings = $this.siblings();

  if ($this.hasClass("wj_active")) {
    $this.children(".vc_tta-panel-body").slideUp();
  } else {
    $this.children(".vc_tta-panel-body").slideDown();
  }

  $this.toggleClass("wj_active");

  siblings.each(function () {
    $(this).children(".vc_tta-panel-body").slideUp();
    $(this).removeClass("wj_active");
  });
});
