;(function($) {

  $.fn.filter = function( options ) {

    var s = $.extend({
           reverse: false
       }, options );

       var current_term = $('.tab a.active').length ? $('.tab a.active').data('key') : '';
       var load_more_button = $('*[data-more]');

    return this.each(function() {

      var el = $(this),
          _action = el.data('filter'),
          _bucket = $( el.data('bucket') ),
          _limit = el.data('limit');

      ;(function __init() {

        assign_listeners( el.find('*[data-key]') );
        init_load_more();
        clear_filters();


      })();


      function clear_filters() {

          $('.clear-filters').on('click', function(c) {

            c.preventDefault();

            //clear nav
            $('.dev-nav a.active').removeClass('active');
            $('.dev-nav').removeClass('filtered');

            //clear select
            if( $('.selectize').length ) {
              var $select = $('#countries').selectize();
              var control = $select[0].selectize;
              control.setValue('0', true);
            }

            var data = collect_data();

            rebuildUrl( '' );
            rebuildHeaderHTML( data );

            prepare_query('');
          });

      }


      function rebuildHeaderHTML( data ) {

          var term = data.term;
          var taxonomy = data.term_taxonomy;

          $.ajax({
            url: igtools.wp.ajax_url,
            data: {action: 'get_term_header', term: term, taxonomy: taxonomy},
            method: 'POST'
          }).done( function( res ) {

              if( res.status === 'OK' ) {

                if( res.description ) {
                  $('.description').html( res.description ).show();
                } else {
                  $('.description').hide();
                }

                var _title =  _title = 'LATEST DEVELOPMENTS';

                if( res.title ) {
                  _title = res.title;
                }

                $('.entry-title-text').html( _title );

              } else {
                console.log( res.msg );
              }
          });
      }

      function prepare_query(_term, limit) {

        limit = limit || _limit;

        setTimeout(function() {

          run_query(_term, limit);

        }, 500);
      }


      function init_load_more() {

        if( ! load_more_button.length ) {
          return false;
        }

        load_more_button.on('click', function(c) {

            c.preventDefault();

            var _load_more_button  = $(this),
                loaded = $('.list-item').length,
                limit = loaded + _limit;

                if( load_more_button.hasClass('loading') ) {
                  return false;
                }
                _load_more_button.addClass('loading');

                loading_animation();
                preloader();

                prepare_query(current_term, limit);
        });
      }


      function assign_listeners( buttons ) {

          buttons.each( function( i, button ) {

            $(button).on('click', function(c){

                c.preventDefault();

                var a = $(this),
                    _term = a.hasClass('active') ? '' : a.data('key');

                rebuildUrl( _term );
                resolveReverse( a );

                var data = collect_data();

                rebuildHeaderHTML( data );

                preloader( 'start' );

                if( _term ) {
                  current_term = _term;
                }

                prepare_query(_term, _limit);


            });

          } );

      }

      function collect_data() {

          var _data = {};

          _data.term_taxonomy = el.data('term_taxonomy');

          if( el.find('.nav-buttons .active').length ) {
            _data.term = el.find('.nav-buttons .active').data('key');

          } else {
            _data.term = '';
          }

      return _data;
      }


      function  rebuildUrl( _term ) {

          var new_url;

          el.addClass('filtered');

          // category page and same term - uncheck
          if( ! _term && current_term ) {

            new_url = document.location.href.replace( '/' + current_term + '/',  '/');
            el.removeClass('filtered');
            current_term = '';

          // category page and another term
          } else if( current_term && _term ){

            new_url = document.location.href.replace( current_term, _term );

          // global page and term
          } else {
            new_url = document.location.href + _term + '/';
          }

          window.history.replaceState(null, null, new_url);
      }


      function preloader() {

        _bucket.parent().css({'min-height': '0px'});
        _bucket.block({msg: null});
      }


      function run_query(terms, limit) {

          $.ajax({
            url: igtools.wp.ajax_url,
            data: {action: _action, term: terms, limit: limit},
            method: 'POST'
          }).done( function( res ) {

              if( res ) {

                  if( res.status === 'OK' ) {

                    if( ! res.has_more ) {
                      $('.load-more').hide();
                    }else {
                      $('.load-more').show();
                    }

                    loading_animation('stop');
                    _bucket.html( res.html ).slideDown();

                  } else {
                    console.log( res.msg );
                  }

              } else {

                console.error( 'No results.' );
              }

              load_more_button.removeClass('loading');
              _bucket.unblock();
          });

      }

      function loading_animation( mode ) {

        mode = mode || 'start';

        var icon = load_more_button.find('.arrow'),
            a = '&rsaquo;',
            i = 0,
            max = 3;

            load_more_button.removeClass('loaded');

            if( mode === 'stop' ) {

                load_more_button.addClass('loaded');

                setTimeout( function() {
                  load_more_button.find('.text').html('Load more');
                  icon.html( a + a );
                }, 500 );

            return true;
            }

            animate();

            function animate() {

              if( load_more_button.hasClass('loaded') ) {
                return false;
              }

              load_more_button.find('.text').html('Loading');

              var timer = setTimeout( function() {

                  i++;

                  if( i <= max ) {
                      icon.append( a );
                  } else {
                      icon.html( '' );
                      i=0;
                  }

                  animate();

              }, 200 );

            };

      }


      function resolveReverse( item ) {

          if( s.reverse ) {

              if( item.hasClass('active') ) {

                item.removeClass('active');

              } else {

                el.find('a.active').removeClass('active');
                item.addClass('active');
              }

          } else {

            item.addClass('active');
          }

      }


    });

  };

})(jQuery);
