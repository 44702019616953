;(function($) {

  $.fn.filterFaF = function( options ) {

    var s = $.extend({
           reverse: false
       }, options );

       var current_term = $('.tab a.active').length ? $('.tab a.active').data('key') : '';
       var load_more_button = $('*[data-more]');

    return this.each(function() {

      var el = $(this),
          _action = el.data('filter-faf'),
          _bucket = $( el.data('bucket') ),
          _limit = el.data('limit'),
          _trigger = null;

      ;(function __init() {

        assign_button( el.find('*[data-key]') );
        assign_select_update();
        assign_orderby();
        init_load_more();
        clear_filters();

      })();


      function clear_filters() {

          $('.clear-filters').on('click', function(c) {

            c.preventDefault();

            _trigger = 'clear';

            //clear nav
            $('.nav-buttons a.active').removeClass('active');
            el.removeClass('filtered');
            el.removeClass('ordered');

            var data = collect_data();

            rebuildHeaderHTML( data );
            if( current_term ) {
              rebuildUrl( '' );
            }

            prepare_query();
          });

      }


      function init_load_more() {

        if( ! load_more_button.length ) {
          return false;
        }

        load_more_button.on('click', function(c) {

            c.preventDefault();

            _trigger = 'more';

            var _load_more_button  = $(this),
                loaded = $('.topic-item').length,
                limit = loaded + _limit,
                params = collect_data();

                if( load_more_button.hasClass('loading') ) {
                  return false;
                }
                _load_more_button.addClass('loading');

                loading_animation();

                prepare_query( params, limit );
        });
      }


      function assign_orderby() {

          var _orderby = $('*[data-filter-order]');

          $('#orderby a').on('click', function(c) {

              _trigger = 'order';

              c.preventDefault();

              var a = $(this);

              if( ! a.hasClass('active') ) {

                  var order = a.data('value');

                  _orderby.find('a').removeClass('active');
                  a.addClass('active  ');

                  var params = collect_data();

                  prepare_query( params );
              }

          });
      }


      function assign_select_update( select ) {

          $( document ).on("change", '#countries', function(e) {

            _trigger = 'countries';

            if( $(this).val() != '' ) {
              el.addClass('ordered');
            }else {
              el.removeClass('ordered');
            }

            var params = collect_data();

            prepare_query( params );
          });
      }


      function prepare_query( params, limit ) {

        limit = limit || _limit;
        params = params || collect_data();

        preloader( 'start' );

        setTimeout(function() {

            run_query( params, limit);

        }, 500);

      }


      function collect_data() {

          var _data = {};

          _data.term_taxonomy = el.data('term_taxonomy');

          if( el.find('.nav-buttons .active').length ) {
            _data.term = el.find('.nav-buttons .active').data('key');

          } else {
            _data.term = '';
          }

          if( el.find('*[data-filter-taxonomy]').length ) {
            _data.taxonomy = {slug: el.find('*[data-filter-taxonomy]').data('filter-taxonomy'), term: el.find('*[data-filter-taxonomy]').val() };
          }

          if( el.find('*[data-filter-order]').length ) {
            _data.order = {orderby: el.find('*[data-filter-order]').data('filter-order'), order: el.find('*[data-filter-order] .active').data('value') };
          }


      return _data;
      }


      function assign_button( buttons ) {

          buttons.each( function( i, button ) {

            $(button).on('click', function(click) {

                _trigger = 'category';

                click.preventDefault();
                click.stopPropagation();

                var a = $(this);

                resolveReverse( a );

                var data = collect_data();

                rebuildHeaderHTML( data );
                rebuildUrl( data.term );

                if( data.term ) {
                  current_term = data.term;
                }
                prepare_query( data );

            });

          } );

      }


      function rebuildHeaderHTML( data ) {

          var term = data.term;
          var taxonomy = data.term_taxonomy;

          $.ajax({
            url: igtools.wp.ajax_url,
            data: {action: 'get_term_header', term: term, taxonomy: taxonomy},
            method: 'POST'
          }).done( function( res ) {

              if( res.status === 'OK' ) {

                if( res.description ) {
                  $('.description').html( res.description ).show();
                } else {
                  $('.description').hide();
                }

                var _title = 'FACTS AND FIGURES';

                if( res.title ) {
                  _title = res.title;
                }

                $('.entry-title-text').html( _title );

              } else {
                console.log( res.msg );
              }
          });
      }


      function  rebuildUrl( _term ) {

          var new_url;

          el.addClass('filtered');

          // category page and same term - uncheck
          if( ! _term && current_term ) {

            new_url = document.location.href.replace( '/' + current_term + '/',  '/');
            el.removeClass('filtered');
            current_term = '';

          // category page and another term
          } else if( current_term && _term ){

            new_url = document.location.href.replace( current_term, _term );

          // global page and term
          } else {
            new_url = document.location.href + _term + '/';
          }

          window.history.replaceState(null, null, new_url);
      }


      function preloader() {

        _bucket.parent().css({'min-height': '0px'});
        _bucket.block({msg: null});
      }


      function run_query(params, limit) {

          if( _trigger && _trigger != 'countries' ) {
            countries.load( params.term );
          }

          $.ajax({
            url: igtools.wp.ajax_url,
            data: {action: _action, params: params, limit: limit},
            method: 'POST'
          }).done( function( res ) {

              if( res ) {

                  if( res.status === 'OK' ) {

                    if( ! res.has_more ) {
                      $('.load-more').hide();
                    }else {
                      $('.load-more').show();
                    }

                    loading_animation('stop');
                    _bucket.html( res.html ).slideDown();

                  } else {
                    console.log( res.msg );
                  }

              } else {

                console.log( 'No results.' );
              }

              load_more_button.removeClass('loading');
              _bucket.unblock();
          });

      }


      function loading_animation( mode ) {

        mode = mode || 'start';

        var icon = load_more_button.find('.arrow'),
            a = '&rsaquo;',
            i = 0,
            max = 3;

            load_more_button.removeClass('loaded');

            if( mode === 'stop' ) {

                load_more_button.addClass('loaded');

                setTimeout( function() {
                  load_more_button.find('.text').html('Load more');
                  icon.html( a + a );
                }, 500 );

            return true;
            }

            animate();

            function animate() {

              if( load_more_button.hasClass('loaded') ) {
                return false;
              }

              load_more_button.find('.text').html('Loading');

              var timer = setTimeout( function() {

                  i++;

                  if( i <= max ) {
                      icon.append( a );
                  } else {
                      icon.html( '' );
                      i=0;
                  }

                  animate();

              }, 200 );

            };

      }


      function resolveReverse( item ) {

          if( s.reverse ) {

              if( item.hasClass('active') ) {

                item.removeClass('active');

              } else {

                el.find('.nav-buttons a.active').removeClass('active');
                item.addClass('active');
              }

          } else {

            item.addClass('active');
          }

      }


    });

  };

})(jQuery);
